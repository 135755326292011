import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useEffect } from "react";
import { BackgroundENVModel, LensModel } from "../../utils/commonInterfaces";

const MeshWithGLBModel: React.FC<{
  isBookObject: boolean;
  isMobileObject: boolean;
  LensModels: LensModel;
}> = ({ isBookObject, isMobileObject, LensModels }) => {
  const bookTexture = useGLTF("/texture/Book.glb");
  const mobileTexture = useGLTF("/texture/Phone.glb");

  const { camera } = useThree();
  const meshRef = useRef<THREE.Mesh | any>(null);

  const texture = isBookObject
    ? bookTexture
    : isMobileObject
    ? mobileTexture
    : null;

  useEffect(() => {
    if (camera && meshRef.current) {
      meshRef.current.position.set(0, 1, 0);
    }
  }, [camera]);

  useFrame(() => {
    if (camera && meshRef.current) {
      const applyTextureTransformations = (
        scale: number | null,
        translateY?: number,
        translateZ?: number,
        translateX?: number
      ) => {
        if (isBookObject || isMobileObject) {
          meshRef.current.position.copy(camera.position);
          meshRef.current.quaternion.copy(camera.quaternion);
        }
        meshRef.current.position.set(0, 1, 0); 

        if (scale !== null) {
          meshRef.current.scale.set(scale, scale, scale);
        }
        if (translateY && translateZ) {
          meshRef.current.translateY(translateY);
          meshRef.current.translateZ(translateZ);
        }
        if (translateX) {
          meshRef.current.translateX(translateX);
        }
      };

      switch (LensModels.techName) {
        case "Neo Digi":
          isBookObject
            ? applyTextureTransformations(40, -2.8, -2, -0.2)
            : applyTextureTransformations(32, -3.2, -1.2, -0.2);
          break;
        case "Neo Pro":
          isBookObject
            ? applyTextureTransformations(40, -2.8, -2, -0.2)
            : applyTextureTransformations(32, -4.2, -1.2, -0.07);
          break;
        case "Neo Uno":
          isBookObject
            ? applyTextureTransformations(40, -2.8, -2, -0.2)
            : applyTextureTransformations(32, -4.2, -1.2, -0.1);
          break;
        case "Neo Sync":
          isBookObject
            ? applyTextureTransformations(40, -2.8, -2, -0.2)
            : applyTextureTransformations(32, -4.2, -1.2, -0.07);
          break;
        case "Neo Space":
        case "Neo Ergo":
        case "Neo Binocs":
        case "K Bi Focal (Digital)":
        case "DT Bi Focal":
          isBookObject
            ? applyTextureTransformations(40, -2.8, -2, -0.2)
            : applyTextureTransformations(32, -4.2, -1.2, -0.07);
          break;
        default:
          break;
      }
    }
  });

  return texture && <primitive ref={meshRef} object={texture.scene} />;
};

export default MeshWithGLBModel;
