import { ClearSiteAnimation } from "../utils/commonInterfaces";
import ClearSightBestClarity from "../assets/animations/best clarity-clearsight.mp4";
import ClearSightZBestClarity from "../assets/animations/best clarity-clearsightz.mp4";
import ClearSightHighestTransparency from "../assets/animations/Heighest Transparency-clearSight.mp4";
import ClearSightZHighestTransparency from "../assets/animations/Heighest Transparency-clearSightZ.mp4";
import ScratchResistance from "../assets/animations/scratch-resistance.mp4";
import Oleophobic from "../assets/animations/oleophobic.mp4";
import Hydrophobic from "../assets/animations/Hydrophobic.mp4";
import UVProtection from "../assets/animations/UV-Protection.mp4";
import BlueLightFilter from "../assets/animations/Blue-light-filter.mp4";
// import AntiBacterial from "../assets/animations/Anti-Bacterial.mp4";
import DriveZAnimationVideo from "../assets/animations/DriveZ_technology.mp4";

export const clearSightBestClarityData: ClearSiteAnimation[] = [
  {
    displayName: "Best Clarity",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ClearSightBestClarity,
    addOn: true,
    title: "ClearSight",
  },
];

export const clearSightZBestClarityData: ClearSiteAnimation[] = [
  {
    displayName: "Best Clarity",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ClearSightZBestClarity,
    addOn: true,
    title: "ClearSightZ",
  },
];

export const clearSightHightstTransparencyData: ClearSiteAnimation[] = [
  {
    displayName: "Highest Transparency",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ClearSightHighestTransparency,
    addOn: true,
    title: "ClearSight",
  },
];

export const clearSightZHightstTransparencyData: ClearSiteAnimation[] = [
  {
    displayName: "Highest Transparency",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ClearSightZHighestTransparency,
    addOn: true,
    title: "ClearSightZ",
  },
];

export const clearSiteAnimationData: ClearSiteAnimation[] = [
  {
    displayName: "Highest Durability",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ScratchResistance,
    addOn: true,
    title: "ClearSight",
  },
  {
    displayName: "Oleophobic",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: Oleophobic,
    addOn: true,
    title: "ClearSight",
  },
  {
    displayName: "Hydrophobic",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: Hydrophobic,
    addOn: true,
    title: "ClearSight",
  },
  {
    displayName: "Full UV Protection",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: UVProtection,
    addOn: true,
    title: "ClearSight",
  },
];

export const clearSiteZAnimationData: ClearSiteAnimation[] = [
  // {
  //   displayName: "Anti Viral & Anti Bacterial",
  //   benefits: [
  //     "Greater vertical area with 100% of the addition",
  //     "Smooth transition between near and far vision",
  //     "Comfortable vision while reading",
  //     "Improved adaptation",
  //     "Ergonomic design that prevents uncomfortable postures",
  //   ],
  //   animationSource: AntiBacterial,
  //   addOn: true,
  //   title: "ClearSightZ",
  // },
];

export const HMCAnimationData: ClearSiteAnimation[] = [
  {
    displayName: "Scratch Resistant",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: ScratchResistance,
    addOn: true,
    title: "HMC",
  },
  // {
  //   displayName: "Anti-Reflective Coating",
  //   benefits: [
  //     "Greater vertical area with 100% of the addition",
  //     "Smooth transition between near and far vision",
  //     "Comfortable vision while reading",
  //     "Improved adaptation",
  //     "Ergonomic design that prevents uncomfortable postures",
  //   ],
  //   animationSource: AntiBacterial,
  //   addOn: true,
  //   title: "HMC"
  // },
];

export const blueTechAnimationData: ClearSiteAnimation[] = [
  {
    displayName: "Best Blue Light Filter",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: BlueLightFilter,
    addOn: true,
    title: "BlueTech+",
  },
];

export const drivEZAnimationData: ClearSiteAnimation[] = [
  {
    displayName: "DrivEZ",
    benefits: [
      "Improved Contrast with reduced disturbing glare",
      "Improved scratch resistance",
      "Distraction free vision with better smudge,dust,oil and water resistance",
      "Protection from harmful UV rays",
      "Protection from bad blue light emitted bt gadgets",
    ],
    animationSource: DriveZAnimationVideo,
    addOn: true,
    title: "DrivEZ",
  },
  {
    displayName: "Oleophobic",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: Oleophobic,
    addOn: true,
    title: "DrivEZ",
  },
  {
    displayName: "Hydrophobic",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: Hydrophobic,
    addOn: true,
    title: "DrivEZ",
  },
  {
    displayName: "Full UV Protection",
    // benefits: [
    //   "Greater vertical area with 100% of the addition",
    //   "Smooth transition between near and far vision",
    //   "Comfortable vision while reading",
    //   "Improved adaptation",
    //   "Ergonomic design that prevents uncomfortable postures",
    // ],
    animationSource: UVProtection,
    addOn: true,
    title: "DrivEZ",
  },
];
