import NightView from "../assets/images/Night-view.png";
import OutdoorENV from "../assets/images/Outdoor-ENV.png";
import OutdoorENV1 from "../assets/images/Outdoor-ENV-1.png";
import OfficeIndoorFirst from "../assets/images/office-view.png";
import { BackgroundENVModel } from "./commonInterfaces";
import ParkView from "../assets/images/Park-view.png";
import BoatingView from "../assets/images/Boating-view.png";
import Office from "../assets/images/office.png";
import outdoorSunView from "../assets/images/out-door-sun-view.png";
import officeOption1 from '../assets/images/office-option1.png';
import officeOption2 from '../assets/images/officeOption2.png';

export const BackgroundENVModels: BackgroundENVModel[] = [
  // {
  //   name: "outDoor",
  //   model: "Outdoor",
  //   imagePath: OutdoorENV,
  //   rotation: [0, 0, 10],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, 0, 0],
  //   type: "hdr",
  // },
  // {
  //   name: "office",
  //   model: "outdoor1",
  //   imagePath: OutdoorENV1,
  //   rotation: [0, 0, 0],
  //   scale: [40, 40, 40],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, 0, 0],
  //   type: "hdr",
  // },
  // {
  //   name: "office-1",
  //   model: "office3",
  //   imagePath: OfficeIndoorFirst,
  //   rotation: [0, -1.57, -0.05],
  //   scale: [2, 2, 2],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, -0.1, -1],
  //   type: "hdr",
  // },
  // {
  //   name: "night-view",
  //   model: "nightatreet1",
  //   imagePath: NightView,
  //   rotation: [0, -1.57, -0.05],
  //   scale: [2, 2, 2],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, -0.1, -1],
  //   type: "hdr",
  // },
  // {
  //   name: "Office-meeting-room",
  //   model: "indoor_new",
  //   imagePath: Office,
  //   rotation: [0, -1.57, -0.05],
  //   scale: [2, 2, 2],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, -0.1, -1],
  //   type: "hdr",
  // },
  // {
  //   name: "Outdoor-sun-view",
  //   model: "polarized_360",
  //   imagePath: outdoorSunView,
  //   rotation: [0, -1.57, -0.05],
  //   scale: [2, 2, 2],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, -0.1, -1],
  //   type: "hdr",
  // },
  // {
  //   name: "Outdoor-polariz-view",
  //   model: "outdoor_polariz",
  //   imagePath: outdoorSunView,
  //   rotation: [0, -1.57, -0.05],
  //   scale: [2, 2, 2],
  //   cameraPosition: [0, 0, 2],
  //   position: [0, -0.1, -1],
  //   type: "hdr",
  // },
  {
    name: "vr-video",
    model: "videoplayback",
    imagePath: ParkView,
    rotation: [0, -1.57, -0.05],
    scale: [2, 2, 2],
    cameraPosition: [0, 0, 2],
    position: [0, -0.1, -1],
    type: "video",
  },

  {
    name: "driveZ_Video",
    model: "driveZ",
    imagePath: BoatingView,
    rotation: [0, -1.57, -0.05],
    scale: [2, 2, 2],
    cameraPosition: [0, 0, 2],
    position: [0, -0.1, -1],
    type: "video",
  },
  {
    name: "Indoor-jpg",
    model: "Indoor_jpg_3",
    imagePath: officeOption1,
    rotation: [0, -1.57, -0.05],
    scale: [2, 2, 2],
    cameraPosition: [0, 0, 2],
    position: [0, -0.1, -1],
    type: "jpg",
  },
  {
    name: "Office-Indoor-View",
    model: "Office-Indoor-View",
    imagePath: officeOption2,
    rotation: [0, -1.57, -0.05],
    scale: [2, 2, 2],
    cameraPosition: [0, 0, 2],
    position: [0, -0.1, -1],
    type: "jpg",
  },
  ];
