import { LensModel } from "./commonInterfaces";

export const LensModels: LensModel[] = [
  {
    name: "Titan lens",
    model: "Color_model",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: -8,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "color-lens",
    fileType: "glb",
    techName: "",
  },
  {
    name: "Neo_Space_lens",
    model: "Neo_Space",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: -6,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_space_Dottedlines3",
    fileType: "glb",
    techName: "Neo Space",
  },
  {
    name: "Neo_Pro_lens",
    model: "Neo_Pro",
    rotation: [0, 3.2, 0],
    scale: [0.5, 0.5, 0.5],
    powerRange: 6,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Pro",
    fileType: "glb",
    techName: "Neo Pro",
  },
  {
    name: "Neo_Uno_lens",
    model: "Neo_Uno",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 4,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Uno",
    fileType: "glb",
    techName: "Neo Uno",
  },
  {
    name: "Ultima_balanced_lens",
    model: "Ultima_Balanced",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Ultima_Balanced_03",
    fileType: "glb",
    techName: "Ultima",
  },
  {
    name: "Ultima_Near",
    model: "Ultima_near",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Ultima_Near",
    fileType: "glb",
    techName: "Ultima-near",
  },
  {
    name: "Ultima_intermediate",
    model: "Ultima_intermediate",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Ultima_intermediate",
    fileType: "glb",
    techName: "Ultima-intermediate",
  },
  {
    name: "Ultima_Distance",
    model: "Ultima_distance",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Ultima_Distance",
    fileType: "glb",
    techName: "Ultima-distance",
  },
  {
    name: "Neo_Uno_lens",
    model: "Neo_Expert",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: -2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Expert",
    fileType: "glb",
    techName: "Neo Expert",
  },
  {
    name: "Neo_digi_FullFrame_Rightside",
    model: "Neo_digi_fullFrame_rightside",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Digi_Dottedlines",
    fileType: "glb",
    techName: "Neo Digi",
  },
  {
    name: "DrivEZ",
    model: "DrivEZ",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 2, 0.9],
    suffixUrl: "DrivEz_06",
    fileType: "glb",
    techName: "drivEZ",
  },
  {
    name: "BiFocal_D",
    model: "BiFocalD",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "BiFocal_D",
    fileType: "glb",
    techName: "DT Bi Focal",
  },
  {
    name: "BiFocal_K",
    model: "BiFocalK",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 5, 0.9],
    suffixUrl: "BifocalK_Dottedlines",
    fileType: "glb",
    techName: "K Bi Focal",
  },
  {
    name: "BiFocal_K_digital",
    model: "BiFocalKDigital",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 1, 0.9],
    suffixUrl: "BiFocalK_04",
    fileType: "glb",
    techName: "K Bi Focal (Digital)",
  },
  {
    name: "Neo_Sync_lens",
    model: "Neo_Sync",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Sync_dottedlines_1",
    fileType: "glb",
    techName: "Neo Sync",
  },
  {
    name: "Neo_Ergo_lens",
    model: "Neo_Ergo",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Ergo",
    fileType: "glb",
    techName: "Neo Ergo",
  },
  {
    name: "Neo_Binocs_lens",
    model: "Neo_Binocs",
    rotation: [0, 3.2, 0],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-42, 3, 0.9],
    suffixUrl: "Neo_Binocs",
    fileType: "glb",
    techName: "Neo Binocs",
  },
  {
    name: "Office_Pro_lens",
    model: "Office_Pro",
    rotation: [-42, 0, 4],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-43, 5, 0.9],
    suffixUrl: "Office_pro_dottedlines1",
    fileType: "glb",
    techName: "Office Pro Meeting",
  },
  {
    name: "Office_Pro_lens",
    model: "Office_Pro",
    rotation: [-42, 0, 4],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-43, 5, 0.9],
    suffixUrl: "Office_pro_dottedlines1",
    fileType: "glb",
    techName: "Office Pro Desktop",
  },
  {
    name: "Office_Pro_lens",
    model: "Office_Pro",
    rotation: [-42, 0, 4],
    scale: [5, 5, 5],
    powerRange: 2,
    cameraPosition: [10, 100, 2],
    position: [-43, 5, 0.9],
    suffixUrl: "Office_pro_dottedlines1",
    fileType: "glb",
    techName: "Office Pro Laptop",
  },

];
