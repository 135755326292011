import { LensTechnogolyData } from "./commonInterfaces";

export const singleVisionData: LensTechnogolyData[] = [
  {
    key: "techName",
    value: "Single Vision",
    displayName: "Single Vision",
    isLensColor: false,
  },
  { key: "overallRating", value: 4, displayName: "Over All Rating" },
  {
    key: "classic",
    value: 1.49,
    displayName: "Refractive Index",
  },
  {
    key: "classic",
    value: "-8.00 to +8.00 Upto -4 cyl",
    defaultRange: { min: -8.0, max: 6.5 },
    clearRange: { min: -9.0, max: 22.0 },
    displayName: "Power Range",
  },
  {
    key: "classic",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "classic",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "classic",
    value: false,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "classic",
    value: 2,
    displayName: "High Power Suitability",
  },
  {
    key: "polycarbonate",
    value: 1.59,
    displayName: "Refractive Index",
  },
  {
    key: "polycarbonate",
    value: "-10.75 to +7.00 Upto -5 cyl",
    defaultRange: { min: -9.0, max: 7 },
    clearRange: { min: -10.75, max: 8.0 },
    displayName: "Power Range",
  },
  {
    key: "polycarbonate",
    value: true,
    displayName: "Shatter Resistance",
  },
  {
    key: "polycarbonate",
    value: "20% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "polycarbonate",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "polycarbonate",
    value: 3,
    displayName: "High Power Suitability",
  },
  {
    key: "thinLens",
    value: 1.67,
    displayName: "Refractive Index",
  },
  {
    key: "thinLens",
    value: "-13.00 to +8.50 Upto -6 cyl",
    clearRange: { min: -13.0, max: 8.5 },
    defaultRange: { min: -13.0, max: 8.5 },
    displayName: "Power Range",
  },
  {
    key: "thinLens",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "thinLens",
    value: "33% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "thinLens",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "thinLens",
    value: 4,
    displayName: "High Power Suitability",
  },
  {
    key: "ultraThin",
    value: 1.74,
    displayName: "Refractive Index",
  },
  {
    key: "ultraThin",
    value: "-16.00 to _9.00 Upto -4 cyl",
    clearRange: { min: -16.0, max: 9.0 },
    displayName: "Power Range",
  },
  {
    key: "ultraThin",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "ultraThin",
    value: "45% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "ultraThin",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "ultraThin",
    value: 5,
    displayName: "High Power Suitability",
  },
  {
    key: "index1.53",
    value: 1.53,
    displayName: "Refractive Index",
  },
  {
    key: "index1.53",
    value: "-9.00 to +6.50 Upto -5 cyl",
    defaultRange: { min: -9.0, max: 7 },
    displayName: "Power Range",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "index1.6",
    value: 1.6,
    displayName: "Refractive Index",
  },
  {
    key: "index1.6",
    value: "-11.00 to +7.00 Upto -4 cyl",
    defaultRange: { min: -9.0, max: 7 },
    displayName: "Power Range",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "coating",
    value: "",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "hmc",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "clearSightZ",
    displayName: "ClearSightZ",
  },
  {
    key: "material",
    value: "classic",
    displayName: "1.49",
  },
  {
    key: "material",
    value: "polycarbonate",
    displayName: "1.59",
  },
  {
    key: "material",
    value: "thinLens",
    displayName: "1.67",
  },
  {
    key: "material",
    value: "ultraThin",
    displayName: "1.74",
  },
  // {
  //   key: "material",
  //   value: "index1.53",
  //   displayName: "1.53",
  // },
  // {
  //   key: "material",
  //   value: "index1.6",
  //   displayName: "1.6",
  // },
  {
    key: "material",
    value: "",
    displayName: "1.56",
  },
  // {
  //   key: "treatment",
  //   value: "clear",
  //   displayName: "Clear",
  // },
  {
    key: "treatment",
    value: "photoChromatic",
    displayName: "Photochromatic",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "polarized",
    displayName: "Polarised",
  },
  {
    key: "treatment",
    value: "tinted",
    displayName: "Tint",
  },
  {
    key: "treatment",
    value: "mirror",
    displayName: "Mirror",
  },
  // {
  //   key: "prTech",
  //   value: "variableFhAndInset",
  //   displayName: "Variable FH & Inset",
  // },
  // {
  //   key: "prTech",
  //   value: "digiLifeTechnology",
  //   displayName: "DigiLife Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "customFitTechnology",
  //   displayName: "CustomFit Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "clearMaxTechnology",
  //   displayName: "ClearMax Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "",
  //   displayName: "Binocular Balance Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "",
  //   displayName: "OptiThin Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "",
  //   displayName: "ErgoMax Technology",
  // },
  // {
  //   key: "prTech",
  //   value: "",
  //   displayName: "ComfortView Technology",
  // },
  // {
  //   key: "ultimaTech",
  //   value: "",
  //   displayName: "Stabilize Technology",
  // },
  // {
  //   key: "ultimaTech",
  //   value: "",
  //   displayName: "Eye-Gaze Technology",
  // },
  // {
  //   key: "ultimaTech",
  //   value: "",
  //   displayName: "Blend Technology",
  // },
];
