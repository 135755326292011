import React, { useContext, useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  Gltf,
  OrbitControls,
  TransformControls,
  useGLTF,
} from "@react-three/drei";
import { LensModel } from "../../utils/commonInterfaces";
import * as THREE from "three";
import { ColorContext } from "../../context/colorContext";
import { Camera } from "three";
import { OrbitControlsChangeEvent } from "@react-three/drei";
interface LensSceneProps {
  LensModels: LensModel;
  isRotate: boolean;
}

const LensScene: React.FC<LensSceneProps> = ({ LensModels, isRotate }) => {
  const group = useRef<THREE.Group>(new THREE.Group());
  const colorContext = useContext(ColorContext);
  const gradientTexture = useRef<THREE.Texture | null>(null);
  const { scene, animations } = useGLTF(
    `/${LensModels.model}/${LensModels.suffixUrl}.${LensModels.fileType}`
  );
  const mixerRef = useRef<THREE.AnimationMixer | null>(null);
  const transformRef = useRef<any>(null);
  const { camera, gl } = useThree();
  const [isContextLost, setIsContextLost] = useState(false);

  const calculateTranslateZ = () => {
    const width = window.innerWidth;
    if (width < 600) {
      return -0.25;
    } else if (width < 992) {
      return -0.25;
    } else if (width < 1200) {
      return -0.25;
    }  else if (width < 1300) {
      return -0.25;
    } else if (width < 1500) {
      return -0.35;
    } else if (width < 2400) {
      return -0.35;
    } else {
      return -0.35;
    }
  };

  useEffect(() => {
    if (transformRef.current) {
      transformRef.current.children.forEach((child: any) => {
        if (
          child.type === "TransformControlsPlane" ||
          child.type === "TransformControlsGizmo"
        ) {
          child.visible = false;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (group.current && scene) {
      group.current.clear();
      group.current.add(scene);

      if (animations.length > 0) {
        const animatededObject = scene.children.find((child) =>
          child.name.endsWith("Dimension")
        );
        if (animatededObject) {
          mixerRef.current = new THREE.AnimationMixer(animatededObject);
          const clip = animations[0];
          const action = mixerRef.current.clipAction(clip);
          action.setLoop(THREE.LoopRepeat, Infinity);
          action.play();
          animatededObject.visible = isRotate;
        }
      }
    }
  }, [LensModels.model, scene, animations, isRotate]);

  useFrame((_, delta) => {
    if (mixerRef.current) {
      mixerRef.current.timeScale = 5;
      mixerRef.current.update(delta);
    }
  });

  useEffect(() => {
    const handleContextLost = (event: Event) => {
      event.preventDefault();
      setIsContextLost(true);
    };

    const handleContextRestored = () => {
      setIsContextLost(false);
    };

    const canvas = gl.domElement;
    canvas.addEventListener("webglcontextlost", handleContextLost);
    canvas.addEventListener("webglcontextrestored", handleContextRestored);

    return () => {
      canvas.removeEventListener("webglcontextlost", handleContextLost);
      canvas.removeEventListener("webglcontextrestored", handleContextRestored);
    };
  }, [gl]);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    const color = colorContext.selectedColor?.path || null;
    const selectedColor1 = colorContext.selectedColor?.color1 || null;
    const selectedColor2 = colorContext.selectedColor?.color2 || null;
    if (color && LensModels.model === "Color_model") {
      loader.load(color, (texture) => {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(4, 4);
        gradientTexture.current = texture;

        if (scene) {
          scene.traverse((child) => {
            if (child.children.length > 0) {
              const child0 = child.children[0];
              const child1 = child.children[1];
              if (child1 instanceof THREE.Mesh) {
                applyTextureToMesh(child1, selectedColor1, selectedColor2);
              }
              if (child0 instanceof THREE.Mesh) {
                applyTextureToMesh(child0, selectedColor1, selectedColor2);
              }
            }
          });
        }
      });
    }
  }, [colorContext.selectedColor, scene]);

  function applyTextureToMesh(mesh: THREE.Mesh, color1: any, color2: any) {
    const geometry = mesh.geometry;
    const opacity = color1 === null ? "0.0" : "0.2";

    if (!geometry) {
      return;
    }

    geometry.computeVertexNormals();
    geometry.computeBoundingBox();

    const vertexShader = `
    varying float vHeight;
    void main() {
        vHeight = position.z;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 0.2);
    }
`;

    const fragmentShader = `
    precision highp float;
    varying float vHeight;
    uniform vec3 color1;
    uniform vec3 color2;
    uniform bool useGradient;
    void main() {
        vec3 color = useGradient ? mix(color1, color2, smoothstep(0.00, 1.0, vHeight)) : color1;
        gl_FragColor = vec4(color, ${opacity});
    }
`;

    const uniforms = {
      color1: { value: new THREE.Color(color1) },
      color2: { value: new THREE.Color(color2 || color1) },
      useGradient: { value: color2 !== null },
    };

    const material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
    });

    mesh.material = material;
    mesh.material.needsUpdate = true;
  }

  useFrame(() => {
    if (isContextLost) return;

    if (group.current && camera) {
      const responsiveTranslateZ = calculateTranslateZ(); // this function is used for responsive 3rd model
      const applyTransformations = (
        scale: number | null,
        translateY?: number,
        translateZ?: number,
        translateX?: number
      ) => {
        if (group.current && camera) {
          group.current.position.copy(camera.position);
          group.current.quaternion.copy(camera.quaternion);
          if (scale !== null) {
            group.current.scale.set(scale, scale, scale);
          }
          if (translateX) {
            group.current.translateX(translateX);
          }
          if (translateY && translateZ) {
            group.current.translateY(translateY);
            group.current.translateZ(translateZ);
          }
        }
      };

      switch (LensModels.model) {
        case "Color_model":
          applyTransformations(4, 1, -18);
          break;
        case "Neo_digi_lens":
          applyTransformations(5, 1.5, -20);
          group.current.rotateY(Math.PI);
          break;
        case "Neo_digi":
          applyTransformations(20, 3, -10);
          break;
        case "neo-digi-lens":
          applyTransformations(32, -0.4, -2);
          break;
        case "Neo_digi_fullFrame_rightside":
        case "Neo_Sync":
        case "Neo_Ergo":
        case "Neo_Binocs":
        case "Neo_digi_finail_blur":
        case "Neo_digi_updated":
        case "BiFocalD":
        case "BiFocalK":
        case "Neo_Pro":
        case "Neo_Uno":
        case "Ultima_Balanced":
        case "Ultima_near":
        case "Ultima_distance":
        case "Ultima_intermediate":
        case "Neo_Space":
        case "Office_Pro":
        case "DrivEZ":
        case "Convex_lens":
        case "BiFocalKDigital":
        case "Neo_Expert":
          applyTransformations(32, calculateTranslateZ(), -1.2);
          break;
        default:
          break;
      }
    }
  });
  camera.position.setLength(5);
  const handleZoom = (event?: OrbitControlsChangeEvent): void => {
    if (!event) return;
    const distance = camera.position.length();
    const minDistance = 3;
    const maxDistance = 5;

    if (distance < minDistance) {
      camera.position.setLength(minDistance);
    } else if (distance > maxDistance) {
      camera.position.setLength(maxDistance);
    }
  };
  return (
    <group>
      <TransformControls ref={transformRef} object={scene}>
        <group ref={group} castShadow={false} receiveShadow={false} />
      </TransformControls>
      <OrbitControls
        makeDefault
        enableRotate={true}
        minPolarAngle={isRotate ? 0 : Math.PI * 0.5}
        maxPolarAngle={isRotate ? Math.PI : Math.PI * 0.5}
        // minAzimuthAngle={isRotate ? -Infinity : -Math.PI / 6}
        // maxAzimuthAngle={isRotate ? Infinity : Math.PI / 6}
        enableDamping={true}
        onChange={handleZoom}
      />
    </group>
  );
};

export default LensScene;
