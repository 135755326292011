import { LensTechnogolyData } from "./commonInterfaces";
import neoSyncAnimation from "../assets/animations/Neo-Sync.mp4";
import StabilizeAnimation from "../assets/animations/stabilization.mp4";
import variableFhAndInset from "../assets/animations/Variable-inset-fitting.mp4";
import customFitTechnology from "../assets/animations/Comfort-view.mp4";
import digiLifeTechnology from "../assets/animations/Digi-life.mp4";
import clearMaxTechnology from "../assets/animations/Clearmax-Technology.mp4";
import optiThinTechnology from "../assets/animations/Opti-thin-Lens.mp4";
import ergoMaxTechnology from "../assets/animations/ErgoMax-Technology.mp4";
import ComfortView from "../assets/animations/Comfort-view.mp4";
import EyeGaze from "../assets/animations/eyeGaze.mp4";

export const neoSyncData: LensTechnogolyData[] = [
  {
    key: "name",
    value: "Neo Sync",
    displayName: "Neo Sync",
    animationSource: neoSyncAnimation,
    bgENVName: "Office-meeting-room",
    isLensColor: false,
  },
  { key: "overallRating", value: 7, displayName: "Over All Rating" },
  {
    key: "classic",
    value: 1.49,
    displayName: "Refractive Index",
  },
  {
    key: "classic",
    value: "-8.00 to +8.00 Upto -4 cyl",
    defaultRange: { min: -8.0, max: 6.5 },
    clearRange: { min: -8.0, max: 8.0 },
    displayName: "Power Range",
  },
  {
    key: "classic",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "classic",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "classic",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "classic",
    value: 2,
    displayName: "High Power Suitability",
  },
  {
    key: "polycarbonate",
    value: 1.59,
    displayName: "Refractive Index",
  },
  {
    key: "polycarbonate",
    value: "-10.75 to +7.00 Upto -5 cyl",
    defaultRange: { min: -9.0, max: 6.5 },
    clearRange: { min: -10.75, max: 7.0 },
    displayName: "Power Range",
  },
  {
    key: "polycarbonate",
    value: true,
    displayName: "Shatter Resistance",
  },
  {
    key: "polycarbonate",
    value: "20% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "polycarbonate",
    value: "Suitability For Rimless Frames",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "polycarbonate",
    value: 3,
    displayName: "High Power Suitability",
  },
  {
    key: "thinLens",
    value: 1.67,
    displayName: "Refractive Index",
  },
  {
    key: "thinLens",
    value: "-13.00 to +8.50 Upto -6 cyl",
    clearRange: { min: -13.0, max: 8.5 },
    defaultRange: { min: -11.0, max: 7.0 },
    displayName: "Power Range",
  },
  {
    key: "thinLens",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "thinLens",
    value: "33% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "thinLens",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "thinLens",
    value: 4,
    displayName: "High Power Suitability",
  },
  {
    key: "ultraThin",
    value: 1.74,
    displayName: "Refractive Index",
  },
  {
    key: "ultraThin",
    value: "-16.00 to _9.00 Upto -4 cyl",
    clearRange: { min: -16.0, max: 9.0 },
    displayName: "Power Range",
  },
  {
    key: "ultraThin",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "ultraThin",
    value: "45% thinner",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "ultraThin",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "ultraThin",
    value: 5,
    displayName: "High Power Suitability",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "index1.53",
    value: "-9.00 to +6.50 Upto -5 cyl",
    clearRange: { min: -9.0, max: 6.5 },
    defaultRange: { min: -9.0, max: 6.5 },
    displayName: "Power Range",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "index1.6",
    value: 1.6,
    displayName: "Refractive Index",
  },
  {
    key: "index1.6",
    value: "-11.00 to +7.00 Upto -4 cyl",
    defaultRange: { min: -9.0, max: 6.5 }, //upto -5 cyl
    displayName: "Power Range",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "coating",
    value: "",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "clearSightZ",
    displayName: "ClearSightZ",
  },
  {
    key: "material",
    value: "classic",
    displayName: "1.49",
  },
  {
    key: "material",
    value: "polycarbonate",
    displayName: "1.59",
  },
  {
    key: "material",
    value: "thinLens",
    displayName: "1.67",
  },
  {
    key: "material",
    value: "ultraThin",
    displayName: "1.74",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "polarized",
    displayName: "Polarised",
  },
  {
    key: "treatment",
    value: "tinted",
    displayName: "Tint",
  },
  {
    key: "prTech",
    value: "variableFhAndInset",
    displayName: "Variable FH & Inset",
    benefits: [
      "Provides the first level of personalization in PAL lenses",
      "Adapts to any type of frame.",
    ],
    animationSource: variableFhAndInset,
  },
  {
    key: "prTech",
    value: "digiLifeTechnology",
    displayName: "DigiLife",
    benefits: [
      "Greater vertical area with 100% of the addition",
      "Smooth transition between near and far vision",
      "Comfortable vision while reading",
      "Improved adaptation",
      "Ergonomic design that prevents uncomfortable postures",
    ],
    animationSource: digiLifeTechnology,
  },
  {
    key: "prTech",
    value: "customFitTechnology",
    displayName: "CustomFit",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: customFitTechnology,
  },
  {
    key: "prTech",
    value: "clearMaxTechnology",
    displayName: "ClearMax",
    benefits: [
      "Up to 20% reduction in aberrations",
      "Unmatched visual fields",
      "Minimized swim effect",
      "More comfortable and ergonomic positioning",
      "Maximum visual acuity and comfort",
    ],
    animationSource: clearMaxTechnology,
  },
  {
    key: "prTech",
    value: "binocularBalanceTechnology",
    displayName: "Binocular Balance",
    benefits: [
      "Maximum comfort",
      "Perfect binocular vision",
      "Enhanced intermediate field perception",
      "Optimal vision at all distances",
      "Better merging of lateral images",
      "Improved near vision",
    ],
    animationSource: "",
  },
  {
    key: "prTech",
    value: "optiThinTechnology",
    displayName: "OptiThin",
    benefits: [
      "Thinner, more attractive lenses for consumers",
      "Guaranteed minimum thickness",
      "Better aesthetics with perfectly centered frame shape",
    ],
    animationSource: optiThinTechnology,
  },
  {
    key: "prTech",
    value: "ergoMaxTechnology",
    displayName: "ErgoMax",
    benefits: [
      "Automatic corridor selection",
      "Optimum ergonomics",
      "Easy PAL adaptation",
      "Reduced muscular eye stress",
      "New personalization level",
    ],
    animationSource: ergoMaxTechnology,
  },
  {
    key: "prTech",
    value: "comfortViewTechnology",
    displayName: "ComfortView",
    benefits: [
      "Limitless perception",
      "Accelerated Adaptation",
      "Excellent Visual Comfort",
    ],
    animationSource: ComfortView,
  },
  {
    key: "ultimaTech",
    value: "stabilizeTechnology",
    displayName: "Stabilize",
    animationSource: StabilizeAnimation,
    benefits: [
      "Higher image stability for reduce swim effect",
      "Improve distance peripheral visual acuity",
      "Improved visual performance in intermediate and near.",
    ],
  },
  {
    key: "ultimaTech",
    value: "eye-GazeTechnology",
    displayName: "Eye-Gaze",
    animationSource: EyeGaze,
    benefits: [
      "Wider fields of view",
      "Precise and comforatble vision at every distance and direction of gaze",
      "A more consistent wearer exprience",
      "Near elimination of peripheral blur.",
    ],
  },
];
