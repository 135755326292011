import React, { useEffect, useRef } from "react";
import { LensModel } from "../../utils/commonInterfaces";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

interface CompareGlbLoaderProps {
  LensModels: LensModel;
}

const CompareGlbLoader: React.FC<CompareGlbLoaderProps> = ({ LensModels }) => {
  const compareLensRef = useRef<THREE.Mesh>(null);
  const { camera, gl } = useThree();

  const { scene } = useGLTF(
    `/${LensModels.model}/${LensModels.suffixUrl}.${LensModels.fileType}`
  );

  const calculateScale = () => {
    const width = window.innerWidth;
    if (width > 1400) {
      return 80;
    } else if (width > 1200) {
      return 60;
    } else {
      return 40;
    }
  };
  useFrame(() => {
    if (compareLensRef.current) {
      const scale = calculateScale();
      compareLensRef.current.scale.set(scale, scale, scale);
      if (compareLensRef.current && camera) {
        compareLensRef.current.position.copy(camera.position);
        compareLensRef.current.quaternion.copy(camera.quaternion);
          compareLensRef.current.scale.set(46, 46, 46);
          compareLensRef.current.translateX(0);
          compareLensRef.current.translateY(-0.7);
          compareLensRef.current.translateZ(-1.2);
        }
    }
  });

  return (
    <primitive
      ref={compareLensRef}
      object={scene}
      position={[0, -1, 0]}
    />
  );
};

export default CompareGlbLoader;
