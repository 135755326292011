// import React, { useEffect, useRef } from "react";
// import { useLoader, useFrame, useThree } from "@react-three/fiber";
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
// import {
//   EquirectangularReflectionMapping,
//   Texture,
//   SphereGeometry,
//   MeshBasicMaterial,
//   TextureLoader,
// } from "three";
// import * as THREE from "three";
// import { BackgroundENVModel } from "../../utils/commonInterfaces";
// import { PMREMGenerator } from "three/src/extras/PMREMGenerator";
// interface BackgroundSceneProps {
//   BackgroundENVModels: BackgroundENVModel;
// }

// const BackgroundScene: React.FC<BackgroundSceneProps> = ({
//   BackgroundENVModels,
// }) => {
//   const envLoader =
//     BackgroundENVModels.type === "hdr"
//       ? RGBELoader
//       : BackgroundENVModels.type === "jpg"
//       ? TextureLoader
//       : RGBELoader;
//   const path =
//     BackgroundENVModels.type === "hdr"
//       ? `/hdr/${BackgroundENVModels.model}.hdr`
//       : BackgroundENVModels.type === "jpg"
//       ? `/hdr/${BackgroundENVModels.model}.jpg`
//       : "/hdr/Outdoor.hdr";
//   // const hdrTexture = useLoader(envLoader, path) as Texture;

//   // const { gl, scene } = useThree();
//   // const meshRef = useRef<THREE.Mesh | null>(null);
//   // const pmremGenerator = new PMREMGenerator(gl);
//   // pmremGenerator.compileEquirectangularShader();
//   // useEffect(() => {
//   //   const envMap = pmremGenerator.fromEquirectangular(hdrTexture).texture;
//   //   scene.environment = envMap;
//   //   scene.background = envMap;
//   //   hdrTexture.dispose();
//   //   pmremGenerator.dispose();
//   //   gl.toneMapping = THREE.ACESFilmicToneMapping;
//   //   gl.toneMappingExposure = .7;

//   //   return () => {
//   //     scene.environment = null;
//   //   };
//   // }, [hdrTexture, gl, scene, pmremGenerator]);

//   // const path =
//   //     BackgroundENVModels.type === "hdr"
//   //       ? `/hdr/${BackgroundENVModels.model}.hdr`
//   //       : "/hdr/Outdoor.hdr";
//   const hdrTexture = useLoader(envLoader, path) as Texture;

//   const { gl, scene } = useThree();
//   const meshRef = useRef<THREE.Mesh | null>(null);

//   useEffect(() => {
//     hdrTexture.mapping = EquirectangularReflectionMapping;
//     scene.background = hdrTexture;
//     scene.environment = hdrTexture;

//     gl.toneMapping = THREE.ACESFilmicToneMapping;
//     gl.toneMappingExposure = 0.8;

//     return () => {
//       scene.environment = null;
//     };
//   }, [hdrTexture, gl, scene]);

//   const geometry = new SphereGeometry(100, 32, 32);
//   const material = new MeshBasicMaterial({
//     map: hdrTexture,
//     side: THREE.BackSide,
//     reflectivity: 0.1,
//   });

//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.position.x += 80;
//     }
//   });

//   return (
//     <mesh
//       ref={meshRef}
//       geometry={geometry}
//       material={material}
//       castShadow={false}
//       receiveShadow={false}
//     />
//   );
// };

// const BackgroundCanvas: React.FC<BackgroundSceneProps> = (props) => {
//   return <BackgroundScene {...props} />;
// };

// export default BackgroundCanvas;

import React, { useEffect, useRef } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import {
  EquirectangularReflectionMapping,
  Texture,
  SphereGeometry,
  MeshBasicMaterial,
  TextureLoader,
  WebGLRenderer,
} from "three";
import * as THREE from "three";
import { BackgroundENVModel } from "../../utils/commonInterfaces";

interface BackgroundSceneProps {
  BackgroundENVModels: BackgroundENVModel;
}

const BackgroundScene: React.FC<BackgroundSceneProps> = ({
  BackgroundENVModels,
}) => {
  const path =
    BackgroundENVModels.type === "jpg"
      ? `/hdr/${BackgroundENVModels.model}.jpg`
      : "/hdr/Indoor_jpg_3.jpg";
  const hdrTexture = useLoader(TextureLoader, path) as Texture;

  const { gl, scene } = useThree();
  const meshRef = useRef<THREE.Mesh | null>(null);

  useEffect(() => {
    hdrTexture.mapping = EquirectangularReflectionMapping;
    scene.background = hdrTexture;
    scene.environment = hdrTexture;

    gl.toneMapping = THREE.ACESFilmicToneMapping;
    gl.toneMappingExposure = 0.8;

    return () => {
      scene.environment = null;
    };
  }, [hdrTexture, gl, scene]);

  const geometry = new SphereGeometry(100, 32, 32);
  const material = new MeshBasicMaterial({
    map: hdrTexture,
    side: THREE.BackSide,
  });

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.position.x += 80;
    }
  });

  return <mesh ref={meshRef} geometry={geometry} material={material} />;
};

const BackgroundCanvas: React.FC<BackgroundSceneProps> = (props) => {
  return <BackgroundScene {...props} />;
};

export default BackgroundCanvas;
