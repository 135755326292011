import Transition from "../assets/animations/transition.mp4";
import ClearSiteZ from "../assets/animations/best-clarity-z.mp4";
import Polarized from "../assets/animations/Polarised.mp4";
import Stabilization from "../assets/animations/stabilization.mp4";
import Hydrophobic from "../assets/animations/Hydrophobic.mp4";
import HighestTransparency from "../assets/animations/Highest-Transparency.mp4";
import Photochromatic from "../assets/animations/photochromatic.mp4";
import UVProtection from "../assets/animations/UV-Protection.mp4";

export const hcTreatments = [
  {
    key: "treatment",
    value: "",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "tinted",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const hmcBluTechTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const driveZClearSightTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const neoSpace153HMCTreatments = [
  {
    key: "treatment",
    value: "",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const clearTransitionTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const polycarbonateTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "Polarized",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const material16Treatments = [
  {
    key: "treatment",
    value: "",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const ultraThinTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const neoDigiClassicTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "photochromatic",
    displayName: "Photochromatic",
  },
];
export const kBiFocalClearSunSafeTreatments = [
  {
    key: "treatment",
    value: "",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Photochromatic",
  },
];

export const kBiFocalDigitalTreatments = [
  {
    key: "treatment",
    value: "",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "sunSafe",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "photochromatic",
    displayName: "Photochromatic",
  },
];

export const neoDigiPolycarbonateTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "photochromatic",
    displayName: "Photochromatic",
  },
];

export const neoUnoPolycarbonateTreatments = [
  {
    key: "treatment",
    value: "clear",
    displayName: "Clear",
  },
  {
    key: "treatment",
    value: "",
    displayName: "Tinted",
  },
  {
    key: "treatment",
    value: "",
    displayName: "SunSafe",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "polarized",
    displayName: "Polarized",
  },
  {
    key: "treatment",
    value: "photochromatic",
    displayName: "Photochromatic",
  },
];

export const neoSpace153Coatings = [
  {
    key: "coating",
    value: "",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "hmc",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "",
    displayName: "ClearSightZ",
  },
];

export const withHCCoatings = [
  {
    key: "coating",
    value: "hc",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "hmc",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "clearSightZ",
    displayName: "ClearSightZ",
  },
];

export const finishedSingleVisionTechCoating = [
  {
    key: "coating",
    value: "hc",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "adv",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "hmc",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "clearSightZ",
    displayName: "ClearSightZ",
  },
];

export const geryColor = [
  {
    key: "color",
    value: "",
    displayName: "Brown",
  },
  {
    key: "color",
    value: "grey",
    displayName: "Grey",
  },
  {
    key: "color",
    value: "",
    displayName: "Mirror",
  },
  {
    key: "color",
    value: "",
    displayName: "Tinted",
  },
];
export const clearColor = [
  {
    key: "color",
    value: "",
    displayName: "Brown",
  },
  {
    key: "color",
    value: "",
    displayName: "Grey",
  },
  {
    key: "color",
    value: "",
    displayName: "Mirror",
  },
  {
    key: "color",
    value: "",
    displayName: "Blue",
  },
  {
    key: "color",
    value: "",
    displayName: "Green",
  },
  {
    key: "color",
    value: "",
    displayName: "Pink",
  },
  {
    key: "color",
    value: "",
    displayName: "Amber",
  },
];
export const brownGreyColor = [
  {
    key: "color",
    value: "brown",
    displayName: "Brown",
  },
  {
    key: "color",
    value: "grey",
    displayName: "Grey",
  },
  {
    key: "color",
    value: "",
    displayName: "Mirror",
  },
  {
    key: "color",
    value: "",
    displayName: "Blue",
  },
  {
    key: "color",
    value: "",
    displayName: "Green",
  },
  {
    key: "color",
    value: "",
    displayName: "Pink",
  },
  {
    key: "color",
    value: "",
    displayName: "Amber",
  },
];

export const allColor = [
  {
    key: "color",
    value: "brown",
    displayName: "Brown",
  },
  {
    key: "color",
    value: "grey",
    displayName: "Grey",
  },
  {
    key: "color",
    value: "mirror",
    displayName: "Mirror",
  },
  {
    key: "color",
    value: "blue",
    displayName: "Blue",
  },
  {
    key: "color",
    value: "green",
    displayName: "Green",
  },
  {
    key: "color",
    value: "pink",
    displayName: "Pink",
  },
  {
    key: "color",
    value: "amber",
    displayName: "Amber",
  },
];

export const mirrorColor = [
  {
    key: "color",
    value: "",
    displayName: "Brown",
  },
  {
    key: "color",
    value: "",
    displayName: "Grey",
  },
  {
    key: "color",
    value: "mirror",
    displayName: "Mirror",
  },
  {
    key: "color",
    value: "",
    displayName: "Blue",
  },
  {
    key: "color",
    value: "",
    displayName: "Green",
  },
  {
    key: "color",
    value: "",
    displayName: "Pink",
  },
  {
    key: "color",
    value: "",
    displayName: "Amber",
  },
];

export const LENS_PARAMETERS = {
  REFRACTIVE_INDEX: "Refractive Index",
  HIGH_POWER_SUITABILITY: "High Power Suitability",
  SHATTER_RESISTANCE: "Shatter Resistance",
  SUITABILITY_FOR_RIMLESS_FRAMES: "Suitability For Rimless Frames",
  POWER_RANGE: "Power Range",
  THICKNESS_COMPARED_TO_CLASSIC_LENS: "Thickness Compared to Classic Lens",
};

export const BUTTON_LABELS = {
  COMPARE_WITH_OTHER_TECH: "Compare",
  CANCEL_COMPARISON: "Cancel Compare",
  ACCORDION_BENEFITS: "Benefits",
  MOBILE_VIEW: "Mobile",
  BOOK_VIEW: "Book",
};

export const SECTION_HEADINGS = {
  AVAIABLE_PARAMETERS: "Available Parameters",
  COLORS: "Colors",
  ADD_ONS: "Add ons",
  MATERIALS: "Materials",
  COATINGS: "Coatings",
  NEO_PROGRESSIVE_TECH: "Neo progressive Technologies",
  ULTIMA_TECH: "Ultima Technologies",
  CATEGORY_BI_FOCAL: "Bi Focal",
  CATEGORY_OFFICE_PRO: "Office Pro",
  CATEGORY_PROGRESSIVE: "Progressive",
  CATEGORY_SINGLE_VISION: "Single Vision",
};
export const DEFAULT_SELECTED_COMPARISON_TECH = {
  TECHNOLOGY_K_BI_FOCAL: "K Bi Focal",
  TECHNOLOGY_DT_BI_FOCAL: "DT Bi Focal",
  TECHNOLOGY_NEO_PRO: "Neo Ergo",
  TECHNOLOGY_OFFICE_PRO_MEETING: "Office Pro Meeting",
};
