import React, { useState, useContext, useEffect } from "react";
import LensCategories from "./components/LensCategories";
import { LensTechnogolyData, LensModel } from "./utils/commonInterfaces";
import LensLoader from "./widgets/LensLoader";
import BackgroundSelection from "./widgets/BackgroundSelection";
import { BackgroundENVModels } from "./utils/backgroundENV";
import { LensModels } from "./utils/lensModels";
import { InfoAnimContext, InfoAnimation } from "./context/infoAnimContext";
import { TechContext } from "./context/technologyContext";
import CompareTechnologies from "./components/CompareTechnologies";
import { lensCategories } from "./utils/lensCategories";
import { DEFAULT_SELECTED_COMPARISON_TECH } from "./utils/constants";
import LensModeles from "./components/LensModels";
import LensParameters from "./components/LensParameters";
import { MaterialContext } from "./context/materialContext";
import CheckboxButton from "./widgets/RadioButton";
import AnimationDialog from "./components/AnimationDialog";
import UltimaVariations from "./components/UltimaVariations";
import { UltimaVariationsData } from "./utils/ultimaData";
function App() {
  const materialContext = useContext(MaterialContext);
  const [filteredENVModel, setFilteredENVModel] = useState(
    BackgroundENVModels[0]
  );
  const [filteredLensModel, setFilteredLensModel] = useState<LensModel | null>(
    null
  );
  const [activeENV, setActiveENV] = useState(BackgroundENVModels[0].name);
  const infoAnimContext = useContext(InfoAnimContext);
  const techContext = useContext(TechContext);
  const [animationInfo, setAnimationInfo] = useState<InfoAnimation[]>([]);
  const [isCompare, setIsCompare] = useState(false);
  const [isBookObject, setIsBookObject] = useState(false);
  const [isMobileObject, setIsMobileObject] = useState(false);
  const [isCompareTech, setIsCompareTech] = useState(false);
  const [ultimaSelectedTech, setUltimaSelectedTech] = useState("Ultima");
  const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>([
    DEFAULT_SELECTED_COMPARISON_TECH.TECHNOLOGY_K_BI_FOCAL,
    DEFAULT_SELECTED_COMPARISON_TECH.TECHNOLOGY_DT_BI_FOCAL,
  ]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("biFocal");

  const filteredOptions = lensCategories.filter(
    (item) => item.key === selectedCategory
  );

  const ChnageBackgroundENV = (ENVName: string) => {
    const filteredModels = BackgroundENVModels.filter(
      (model) => model?.name! === ENVName
    );
    setFilteredENVModel(filteredModels[0]);
    setActiveENV(ENVName);
  };

  const handleChange = (selectedLensData: LensTechnogolyData[]) => {
    if (selectedLensData[0]?.isLensColor) {
      setFilteredLensModel(LensModels[0]);
    } else {
      const lensfilteredModels = LensModels.filter(
        (LensModel) => LensModel?.techName! === selectedLensData[0]?.value
      );
      setFilteredLensModel(
        lensfilteredModels.length > 0 ? lensfilteredModels[0] : null
      );
    }

    const filteredBgModels = BackgroundENVModels.filter(
      (bgModel) => bgModel?.name! === selectedLensData[0]?.bgENVName
    );
    const filteredBgEnvModels =
      filteredBgModels.length > 0 ? filteredBgModels[0] : filteredENVModel;
    setFilteredENVModel(filteredBgEnvModels);
    setActiveENV(filteredBgEnvModels.name);
  };

  useEffect(() => {
    if (!techContext?.technology?.[0]) return;
    handleChange(techContext?.technology);
    const { benefits, displayName, animationSource } =
      techContext.technology[0];
    const defaultCompare =
      infoAnimContext.selectedInfoAnim[0]?.isCompareTechnology ?? false;
    const newData = {
      benefits,
      displayName,
      animationSource,
      isCompareTechnology: defaultCompare,
    };

    const updateInfoAnim = (newAnimInfo: InfoAnimation[]) => {
      setAnimationInfo(newAnimInfo);
      infoAnimContext.setSelectedInfoAnim(newAnimInfo);
    };

    if (
      animationInfo.length === 0 ||
      (!isCompare && animationInfo.length === 1)
    ) {
      updateInfoAnim([newData]);
    } else if (isCompare) {
      const updatedAnimInfo =
        animationInfo.length === 1 ? [...animationInfo, newData] : [newData];
      updateInfoAnim(updatedAnimInfo);
    } else if (
      animationInfo[1].addOn! === false &&
      animationInfo.length === 2
    ) {
      updateInfoAnim([...animationInfo.slice(1)]);
    } else if (animationInfo[1].addOn! === true && animationInfo.length === 2) {
      updateInfoAnim([newData]);
    } else if (animationInfo.length > 2) {
      updateInfoAnim([newData]);
    }
  }, [techContext]);

  useEffect(() => {
    if (!isCompare && animationInfo.length === 2) {
      setAnimationInfo([...animationInfo.slice(1)]);
    }
  }, [isCompare]);

  useEffect(() => {
    if (infoAnimContext.selectedInfoAnim[0]?.addOn === true && !isCompare) {
      const updatedAnimInfo =
        animationInfo.length === 1
          ? [...infoAnimContext.selectedInfoAnim, ...animationInfo]
          : animationInfo.length === 2
          ? [...infoAnimContext.selectedInfoAnim, ...animationInfo.slice(1)]
          : animationInfo.length > 2
          ? [
              ...infoAnimContext.selectedInfoAnim,
              animationInfo[animationInfo.length - 1],
            ]
          : [...infoAnimContext.selectedInfoAnim];

      setAnimationInfo(updatedAnimInfo);
      setIsDialogOpen(true);
    }
  }, [infoAnimContext]);

  const handleTechChange = (index: number, newTech: string) => {
    const newSelectedValues = [...selectedTechnologies];
    newSelectedValues[index] = newTech as string;
    setSelectedTechnologies(newSelectedValues);
  };

  const UltimaLensModelChange = (slectedVarient: string) => {
    setUltimaSelectedTech(slectedVarient);
    const lensfilteredModels = LensModels.filter(
      (LensModel) => LensModel?.techName! === slectedVarient
    );
    setFilteredLensModel(lensfilteredModels[0]);
  };

  return (
    <>
      <LensCategories
        isCompareTech={isCompareTech}
        setIsCompareTech={setIsCompareTech}
        activeENV={activeENV}
        isBookObject={isBookObject}
        setIsBookObject={setIsBookObject}
        isMobileObject={isMobileObject}
        setIsMobileObject={setIsMobileObject}
      />
      <AnimationDialog
        animationInfo={animationInfo}
        isCompareTech={isCompareTech}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />

      {!isCompareTech ? (
        <>
          <div className="container-main">
            <LensParameters />
            <div className="selected_tech">
              <span>{techContext?.technology?.[0]?.displayName}</span>
            </div>
            <LensLoader
              BackgroundENVModels={filteredENVModel}
              LensModels={filteredLensModel}
              isBookObject={isBookObject}
              isMobileObject={isMobileObject}
            />{" "}
            <BackgroundSelection
              ChnageBackgroundENV={ChnageBackgroundENV}
              BackgroundENVModels={BackgroundENVModels}
              activeENV={activeENV}
              isBookObject={isBookObject}
              setIsBookObject={setIsBookObject}
              isMobileObject={isMobileObject}
              setIsMobileObject={setIsMobileObject}
            />
            {techContext?.technology?.[0]?.value === "Ultima" && (
              <UltimaVariations
                UltimaLensModelChange={UltimaLensModelChange}
                ultimaVariationsData={UltimaVariationsData}
                ultimaSelectedTech={ultimaSelectedTech}
              />
            )}
            <LensModeles
              selectedMaterialValue={materialContext.selectedMaterial}
            />
          </div>
        </>
      ) : (
        <div className="compare-wrapper">
          <CheckboxButton
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <div className="compare-tech-section">
            {selectedTechnologies.map((selectedValue, index) => {
              const filteredOptionsForDropdown = filteredOptions.filter(
                (item) => !selectedTechnologies.includes(item.displayName)
              );
              return (
                <CompareTechnologies
                  key={index}
                  defaultTech={selectedValue}
                  filteredOptions={filteredOptions}
                  availableOptions={filteredOptionsForDropdown}
                  onTechChange={(newTech: string) =>
                    handleTechChange(index, newTech)
                  }
                  isBookObject={isBookObject}
                  setIsBookObject={setIsBookObject}
                  isMobileObject={isMobileObject}
                  setIsMobileObject={setIsMobileObject}
                  ChnageBackgroundENV={ChnageBackgroundENV}
                  BackgroundENVModels={BackgroundENVModels}
                  activeENV={activeENV}
                  BackgroundHDRModels={filteredENVModel}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default App;
