import React, { useContext } from "react";
import { TechContext } from "../../context/technologyContext";
import Button from "@mui/material/Button";
import BookIcon from "../../assets/icons/Book.svg";
import MobileIcon from "../../assets/icons/smartphone_1434411.svg";

interface MobileBookTextureProps {
  activeENV: string;
  isBookObject: boolean;
  setIsBookObject: (isBookObject: boolean) => void;
  isMobileObject: boolean;
  setIsMobileObject: (isBookObject: boolean) => void;
}

const MobileBookTexture: React.FC<MobileBookTextureProps> = ({
  activeENV,
  isBookObject,
  setIsBookObject,
  isMobileObject,
  setIsMobileObject,
}) => {
  const techContext = useContext(TechContext);
  const isTextureButtons = !(
    (techContext?.technology[0]?.value?.startsWith("Neo") ||
      techContext?.technology[0]?.value?.startsWith("K") ||
      techContext?.technology[0]?.value?.startsWith("DT")) &&
    activeENV === "Office-meeting-room"
  );
  const enableMobileObject = () => {
    setIsMobileObject(!isMobileObject);
    setIsBookObject(false);
  };
  const enableBookObject = () => {
    setIsBookObject(!isBookObject);
    setIsMobileObject(false);
  };

  return (
    <div className="main-footer_buttons">
      <Button
        variant="contained"
        className={`main-footer__mobile-btn ${
          isTextureButtons
            ? "main-footer_Btn-disable"
            : "main-footer_Btn-enable"
        }`}
        disabled={isTextureButtons}
      >
        <img
          src={MobileIcon}
          alt="MobileIcon"
          className="main-footer__MobileIcon"
          onClick={enableMobileObject}
        />{" "}
      </Button>
      <Button
        variant="contained"
        className={`main-footer__mobile-btn ${
          isTextureButtons
            ? "main-footer_Btn-disable"
            : "main-footer_Btn-enable"
        }`}
        disabled={isTextureButtons}
      >
        <img
          src={BookIcon}
          title="BookIcon"
          alt="BookIcon"
          className="main-footer__BookIcon"
          onClick={enableBookObject}
        />
      </Button>
    </div>
  );
};

export default MobileBookTexture;
