import * as React from "react";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { TechContext } from "../../context/technologyContext";
import { useEffect, useState } from "react";

interface MenuTransitionsProps {
  title: string;
  paramItems: LensTechnogolyData[];
  deafultOpenMenu: boolean;
  onMenuToggle: () => void;
}

const MenuTransitions: React.FC<MenuTransitionsProps> = ({
  paramItems,
  title,
  deafultOpenMenu,
  onMenuToggle
}) => {
  const [selectedParam, setselectedParam] = useState<string>("");
  const techContext = React.useContext(TechContext);

  const handleMenuItemClick = (item: LensTechnogolyData) => {
    setselectedParam(item.value);
    if (
      item.key === "biFocal" ||
      item.key === "officePro" ||
      item.key === "progressive" ||
      item.key === "singleVision"
    ) {
      techContext?.setTechnology(item.data!);
    }
  };
//   const handleCatClick = () => {
//     setIsOpenMenu(!isOpenMenu);
//   };

  useEffect(()=>{
    setselectedParam("dtBiFocal")
  }, [])

  return (
    <div className="menu-wrapper">
      <div className="menu-wrapper__category-dropdown">
        <button className={`menu-wrapper__category-btn ${deafultOpenMenu  && "menu-wrapper__cat-btn-active"}`} onClick={onMenuToggle}>
          {title}
        </button>
        <div>
          {deafultOpenMenu && (
            <ul className="menu-wrapper__menu">
              {paramItems.map(
                (param, index) =>
                  param.value !== "" && (
                    <div key={index}>
                      <li
                        className={`menu-wrapper__menu-item ${
                          selectedParam === param.value && "menu-wrapper__active-menuitem"
                        }`}
                        onClick={() => handleMenuItemClick(param)}
                      >
                        {param.displayName}
                      </li>
                    </div>
                  )
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuTransitions;
