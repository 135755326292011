import Transition from "../assets/animations/transition.mp4";
import ClearSiteZ from "../assets/animations/best-clarity-z.mp4";
import Polarized from "../assets/animations/Polarised.mp4";
import Stabilization from "../assets/animations/stabilization.mp4";
import Hydrophobic from "../assets/animations/Hydrophobic.mp4";
import HighestTransparency from "../assets/animations/Highest-Transparency.mp4";
import Photochromatic from "../assets/animations/photochromatic.mp4";
import UVProtection from "../assets/animations/UV-Protection.mp4";
import RriveZAnimationVideo from "../assets/animations/DriveZ_technology.mp4";
export interface Treatment {
  displayName: string;
  benefits?: string[];
  animationSource: string;
}

export interface TreatmentAnimation {
  // clear: Treatment;
  tinted: Treatment;
  sunSafe: Treatment;
  transitions: Treatment;
  polarized: Treatment;
  photoChromatic: Treatment;
  drivez: Treatment;
}

export const treatmentAnimationData: TreatmentAnimation = {
  // clear: {
  //   displayName: "Clear",
  //   benefits: [
  //     "Wider side-to-side visual fields",
  //     "Reduced distortions for clearer vision",
  //     "Enhanced comfort for your eyes",
  //     "Larger and more comfortable reading area",
  //     "Quicker and easier adjustment",
  //     "Clear vision in every direction you look",
  //     "Fully customized for each individual user",
  //   ],
  //   animationSource: ClearSiteZ,
  // },
  tinted: {
    displayName: "Tinted",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: UVProtection,
  },
  sunSafe: {
    displayName: "SunSafe",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: UVProtection,
  },
  transitions: {
    displayName: "Transitions",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: Transition,
  },
  polarized: {
    displayName: "Polarized",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: Polarized,
  },
  photoChromatic: {
    displayName: "Photochromatic",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: Photochromatic,
  },
  drivez: {
    displayName: "Drivez",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: RriveZAnimationVideo,
  },
};
