import React, { useRef, useEffect, useContext, useState } from "react";
import { Card } from "@mui/material";
import { TechContext } from "../../context/technologyContext";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
interface InfographicProps {
  keyName: string;
  isMaterialThicknessOpen: boolean;
  setIsMaterialThicknessOpen: (info: boolean) => void;
}

interface materialList {
  defaultMaterial: LensTechnogolyData[];
  selectedMaterial: LensTechnogolyData[];
}

const lensData: Record<
  string,
  { centerThickness: number; edgeThickness: number }
> = {
  classic: { centerThickness: 7.26, edgeThickness: 0.65 },
  polycarbonate: { centerThickness: 6.33, edgeThickness: 0.65 },
  thinLens: { centerThickness: 5.74, edgeThickness: 0.6 },
  ultraThin: { centerThickness: 4.86, edgeThickness: 0.6 },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MaterialThicknessComparison: React.FC<InfographicProps> = ({
  keyName,
  isMaterialThicknessOpen,
  setIsMaterialThicknessOpen,
}) => {
  const techContext = useContext(TechContext);
  const [features, setFeatures] = useState<materialList>({
    defaultMaterial: [],
    selectedMaterial: [],
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (techContext) {
      const techData = techContext.technology;
      const classicMaterial = techData.filter((data) => data.key === "classic");
      const selectedMaterial = techData.filter((data) => data.key === keyName);

      setFeatures({
        defaultMaterial: classicMaterial,
        selectedMaterial: selectedMaterial,
      });
    }
  }, [techContext, keyName]);
  const formatValue = (item: any) => {
    if (item.value === true) return "✔️";
    if (item.value === false) return "❌";
    if (item.value === "") return "-";
    if (
      typeof item.value === "number" &&
      item.displayName === "High Power Suitability" &&
      item.value >= 1 &&
      item.value <= 5
    )
      return "⭐".repeat(item.value);
    return item.value;
  };
  const handleClose = () => {
    setIsMaterialThicknessOpen(false);
  };

  const lensThickness = lensData[keyName];

  if (!lensThickness) {
    console.error(`Key "${keyName}" not found in lensData.`);
    return null;
  }

  const classicMaterialPath = `M1.5,414.9C1.5,146.7,64.3,120.1,${
    7.3 * 20
  },1.7V828C64.3,732.7,1.5,583.1,1.5,414.9Z`;

  const materialPath = `M1.5,414.9C1.5,146.7,64.3,120.1,${
    lensThickness.centerThickness * 20
  },1.7V828C64.3,732.7,1.5,583.1,1.5,414.9Z`;

  return (
    <React.Fragment>
      <BootstrapDialog
        className="material-compare-customized-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isMaterialThicknessOpen}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="material-compare-customized-dialog__close-icon"
          sx={(theme: any) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div ref={dropdownRef}>
            {isMaterialThicknessOpen && (
              <>
                <div
                  className={`material-thickness-Comparison ${
                    isMaterialThicknessOpen && "show"
                  }`}
                >
                  <TableContainer component={Paper}>
                    <Card className="material-thickness-Comparison__card">
                      <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`0 0 162.4 834.8`}
                        width="100"
                        height="200"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d={classicMaterialPath}
                          stroke="#231f20"
                          strokeWidth="0.5"
                          fill="url(#linear-gradient)"
                        />
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="74.53"
                            y1="875.43"
                            x2="100.74"
                            y2="-81.81"
                          >
                            <stop offset="0%" stopColor="#87c1e9" />
                            <stop offset="100%" stopColor="#86c1e9" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div>
                        <strong>
                          {lensData["classic"].centerThickness} mm
                        </strong>{" "}
                        (Center Thickness)
                      </div>
                      <div>
                        <strong>{lensData["classic"].edgeThickness} mm</strong>{" "}
                        (Edge Thickness)
                      </div>
                    </Card>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Features</TableCell>
                          <TableCell>Classic</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {features.defaultMaterial.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className="material-thickness-Comparison__table-data">{row.displayName}</TableCell>
                            <TableCell className="material-thickness-Comparison__table-data">{formatValue(row)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Card className="material-thickness-Comparison__card">
                      <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`0 0 162.4 834.8`}
                        width="100"
                        height="200"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d={materialPath}
                          stroke="#231f20"
                          strokeWidth="0.5"
                          fill="url(#linear-gradient)"
                        />
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="74.53"
                            y1="875.43"
                            x2="100.74"
                            y2="-81.81"
                          >
                            <stop offset="0%" stopColor="#87c1e9" />
                            <stop offset="100%" stopColor="#86c1e9" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div>
                        <strong>{lensThickness.centerThickness} mm</strong>{" "}
                        (Center Thickness)
                      </div>
                      <div>
                        <strong>{lensThickness.edgeThickness} mm</strong> (Edge
                        Thickness)
                      </div>
                    </Card>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Features</TableCell>
                          <TableCell>{keyName}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {features.selectedMaterial.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className="material-thickness-Comparison__table-data">{row.displayName}</TableCell>
                            <TableCell className="material-thickness-Comparison__table-data">{formatValue(row)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default MaterialThicknessComparison;
