import { ColorCategory } from "./commonInterfaces";
import gradient from "../assets/images/Gradient1.png";
import Amethyst from "../assets/colors-images/Amethyst.png";
import GradalPacificBlue from "../assets/colors-images/Gradal-Pacific-Blue.png";
import LightSapphireBlue from "../assets/colors-images/Light-Sapphire-Blue.png";
import CapriBlue from "../assets/colors-images/Capri-Blue.png";
import GradalcapriBlue from "../assets/colors-images/Gradal-capri-Blue.png";
import LightPacificBlue from "../assets/colors-images/Light-Pacific-Blue.png";
import OlivineGreen from "../assets/colors-images/Olivine-Green.png";
import GradalOlivineGreen from "../assets/colors-images/Gradal-Olivine-Green.png";
import GradientAmethyst from "../assets/colors-images/Gradient-Amethyst.png";
import LightPeach from "../assets/colors-images/Light-Peach.png";
import GradalPinkRose from "../assets/colors-images/Gradal-Pink-Rose.png";
import GradalMoonstone from "../assets/colors-images/Gradal-Moonstone.png";
import LightOlivineGreen from "../assets/colors-images/Light-Olivine-Green.png";
import EmeraldGreen from "../assets/colors-images/Emerald-Green.png";
import GradalEmerald from "../assets/colors-images/Gradal-Emerald.png";
import GardenGreen from "../assets/colors-images/Garden-Green.png";
import DarkPeach from "../assets/colors-images/Dark-Peach.png";
import GradalPeach from "../assets/colors-images/Gradal-Peach.png";
import GradalAmethyst from "../assets/colors-images/Gradal-Amethyst.png";
import LightAmethyst from "../assets/colors-images/Light-Amethyst.png";
import PinkRose from "../assets/colors-images/Pink-Rose.png";
import LightPinkRose from "../assets/colors-images/Light-Pink-Rose.png";
import Plum from "../assets/colors-images/Plum.png";
import Topaz from "../assets/colors-images/Topaz.png";
import GradalTopaz from "../assets/colors-images/Gradal-Topaz.png";
import LightTopaz from "../assets/colors-images/Light-Topaz.png";
import SmokedTopaz from "../assets/colors-images/Smoked-Topaz.png";
import GradalCitrineAmethyst from "../assets/colors-images/Gradal-Citrine-Amethyst.png";
import GradalSmoked from "../assets/colors-images/Gradal-Smoked.png";
import SmokyQuartz from "../assets/colors-images/Smoky-Quartz.png";
import GradalSmokyQuartz from "../assets/colors-images/Gradal-Smoky-Quartz.png";
import PeachyTopaz from "../assets/colors-images/Peachy-Topaz.png";
import Lime from "../assets/colors-images/Lime.png";
import GradalLime from "../assets/colors-images/Gradal-Lime.png";
import LightLime from "../assets/colors-images/Light-Lime.png";
import Citrine from "../assets/colors-images/Citrine.png";
import GradalCitrine from "../assets/colors-images/Gradal-Citrine.png";
import Sun from "../assets/colors-images/Sun.png";
import GradalSun from "../assets/colors-images/Gradal-Sun.png";
import LightSun from "../assets/colors-images/Light-Sun.png";
import BlackDiamond from "../assets/colors-images/Black-Diamond.png";
import GradalBlackDiamond from "../assets/colors-images/Gradal-Black-Diamond.png";
import ShadowCrystal from "../assets/colors-images/Shadow-Crystal.png";
import GradalShadowCrystal from "../assets/colors-images/Gradal-Shadow-Crystal.png";
import Moonstone from "../assets/colors-images/Moonstone.png";
import LightMoonstone from "../assets/colors-images/Light-Moonstone.png";
import RacingScarlet from "../assets/colors-images/Racing-Scarlet.png";
import TropicalGreen from "../assets/colors-images/Tropical-Green.png";
import GoldDust from "../assets/colors-images/Gold-Dust.png";
import Chrome from "../assets/colors-images/Chrome.png";
import CoolBlue from "../assets/colors-images/Cool-Blue.png";

export const colorsData: ColorCategory = {
  blue: [
    {
      name: "Sapphire Blue",
      value: "#0F52BA",
      path: gradient,
      color1: "#1CA9C9",
      color2: null,
    },
    {
      name: "Gradal Sapphire Blue",
      value: "linear-gradient(to bottom, #1CA9C9, #7FDBFF)",
      path: GradientAmethyst,
      color1: "#1CA9C9",
      color2: "#7FDBFF",
    },
    {
      name: "Light Sapphire Blue",
      value: "#82CFFD",
      path: LightSapphireBlue,
      color1: "#82CFFD",
      color2: null,
    },
    {
      name: "Capri Blue",
      value: "#005B82",
      path: CapriBlue,
      color1: "#005B82",
      color2: "",
    },
    {
      name: "Gradal capri Blue",
      value: "linear-gradient(to bottom, #1CA9C9, #7FDBFF)",
      path: GradalcapriBlue,
      color1: "#1CA9C9",
      color2: "#7FDBFF",
    },
    {
      name: "Pacific Blue",
      value: "#00A5C2",
      path: LightPacificBlue,
      color1: "#00A5C2",
      color2: null,
    },
    {
      name: "Gradal Pacific Blue",
      value: "linear-gradient(to bottom, #1CA9C9, #7FDBFF)",
      path: GradalPacificBlue,
      color1: "#1CA9C9",
      color2: "#7FDBFF",
    },
    {
      name: "Light Pacifis Blue",
      value: "#1CA9C9",
      path: LightPacificBlue,
      color1: "#1CA9C9",
      color2: null,
    },
  ],
  green: [
    {
      name: "Olivine Green",
      value: "#9AB973",
      path: OlivineGreen,
      color1: "#9AB973",
      color2: null,
    },
    {
      name: "Gradal Olivine Green",
      value: "linear-gradient(to bottom, #9AB973, #C4DCA4)",
      path: GradalOlivineGreen,
      color1: "#9AB973",
      color2: "#C4DCA4",
    },
    {
      name: "Light Olivine Green",
      value: "#C4DCA4",
      path: LightOlivineGreen,
      color1: "#C4DCA4",
      color2: null,
    },
    {
      name: "Emerald Green",
      value: "#3E863D",
      path: EmeraldGreen,
      color1: "#3E863D",
      color2: null,
    },
    {
      name: "Gradal Emerald",
      value: "linear-gradient(to bottom, #50C878, #A3E4A0)",
      path: GradalEmerald,
      color1: "#3E863D",
      color2: "#A3E4A0",
    },
    {
      name: "Garden Green",
      value: "linear-gradient(to bottom, #3E863D, #FFFF00)",
      path: GardenGreen,
      color1: "#3E863D",
      color2: "#FFFF00",
    },
  ],
  pink: [
    {
      name: "Light Peach",
      value: "#FFDAB9",
      path: LightPeach,
      color1: "#FFDAB9",
      color2: null,
    },
    {
      name: "Dark Peach",
      value: "#FFB347",
      path: DarkPeach,
      color1: "#FFB347",
      color2: null,
    },
    {
      name: "Gradal Peach",
      value: "linear-gradient(to bottom, #FFDAB9, #FFB347)",
      path: GradalPeach,
      color1: "#FFB347",
      color2: "FFDAB9",
    },
    {
      name: "Amethyst",
      value: "#80609F",
      path: Amethyst,
      color1: "#80609F",
      color2: null,
    },
    {
      name: "Gradal Amethyst",
      value: "linear-gradient(to bottom, #80609F, #D8BFD8)",
      path: GradalAmethyst,
      color1: "#80609F",
      color2: "#D8BFD8",
    },
    {
      name: "Light Amethyst",
      value: "#80609F",
      path: LightAmethyst,
      color1: "#80609F",
      color2: null,
    },
    {
      name: "Pink Rose",
      value: "#FFC0CB",
      path: PinkRose,
      color1: "#FFC0CB",
      color2: null,
    },
    {
      name: "Gradal Pink Rose",
      value: "linear-gradient(to bottom, #FFD1DC, #FFFFFF)",
      path: GradalPinkRose,
      color1: "#FFC0CB",
      color2: "#FFFFFF",
    },
    {
      name: "Light Pink Rose",
      value: "#FEC0E7",
      path: LightPinkRose,
      color1: "#FEC0E7",
      color2: null,
    },
    {
      name: "Plum",
      value: "linear-gradient(to bottom, #800000, #3A3A3A)",
      path: Plum,
      color1: "#800000",
      color2: "#3A3A3A",
    },
  ],
  brown: [
    {
      name: "Topaz",
      value: "#FFA830",
      path: Topaz,
      color1: "#FFA830",
      color2: null,
    },
    {
      name: "Gradal Topaz",
      value: "linear-gradient(to bottom, #FFA830, #FFFFFF)",
      path: GradalTopaz,
      color1: "#FFA830",
      color2: "#FFFFFF",
    },
    {
      name: "Light Topaz",
      value: "#FFC87C",
      path: LightTopaz,
      color1: "#FFC87C",
      color2: null,
    },
    {
      name: "Smoked Topaz",
      value: "#A77930",
      path: SmokedTopaz,
      color1: "#A77930",
      color2: null,
    },
    {
      name: "Gradal Citrine Amethyst",
      value: "linear-gradient(to bottom, #FA8D74, #E4D00A)",
      path: GradalCitrineAmethyst,
      color1: "#FA8D74",
      color2: "#E4D00A",
    },
    {
      name: "Gradal Smoked",
      value: "linear-gradient(to bottom, #8A6017, #CCCCCC)",
      path: GradalSmoked,
      color1: "#8A6017",
      color2: "#CCCCCC",
    },
    {
      name: "Smoky Quartz",
      value: "#6F4800",
      path: SmokyQuartz,
      color1: "#6F4800",
      color2: null,
    },
    {
      name: "Gradal Smoky Quartz",
      value: "linear-gradient(to bottom, #6F4800, #FFFFFF)",
      path: GradalSmokyQuartz,
      color1: "#6F4800",
      color2: "#FFFFFF",
    },
    {
      name: "Peachy Topaz",
      value: "#8C7B71",
      path: PeachyTopaz,
      color1: "#8C7B71",
      color2: null,
    },
  ],
  amber: [
    {
      name: "Lime",
      value: "#DBA400",
      path: Lime,
      color1: "#DBA400",
      color2: null,
    },
    {
      name: "Gradal Lime",
      value: "linear-gradient(to bottom, #DBA400, #FFFFFF)",
      path: GradalLime,
      color1: "#DBA400",
      color2: "#FFFFFF",
    },
    {
      name: "Light Lime",
      value: "#FEDC56",
      path: LightLime,
      color1: "#FEDC56",
      color2: null,
    },
    {
      name: "Citrine",
      value: "#FFE900",
      path: Citrine,
      color1: "#FFE900",
      color2: null,
    },
    {
      name: "Gradal Citrine",
      value: "linear-gradient(to bottom, #E4D00A, #FFF3B8 )",
      path: GradalCitrine,
      color1: "#E4D00A",
      color2: "#FFF3B8",
    },
    {
      name: "Sun",
      value: "#FFBF00",
      path: Sun,
      color1: "#FFBF00",
      color2: null,
    },
    {
      name: "Gradal Sun",
      value: "linear-gradient(to bottom, #FFBF00, #FFED94)",
      path: GradalSun,
      color1: "#FFBF00",
      color2: "#FFED94",
    },
    {
      name: "Light Sun",
      value: "#FEDC56",
      path: LightSun,
      color1: "#FEDC56",
      color2: null,
    },
  ],
  grey: [
    {
      name: "Black Diamond",
      value: "#384C5F",
      path: BlackDiamond,
      color1: "#384C5F",
      color2: null,
    },
    {
      name: "Gradal Black Diamond",
      value: "linear-gradient(to bottom, #384C5F, #FFFFFF)",
      path: GradalBlackDiamond,
      color1: "#384C5F",
      color2: "#FFFFFF",
    },
    {
      name: "Shadow Crystal",
      value: "#445B72",
      path: ShadowCrystal,
      color1: "#445B72",
      color2: null,
    },
    {
      name: "Gradal Shadow Crystal",
      value: "linear-gradient(to bottom, #445B72, #FFFFFF)",
      path: GradalShadowCrystal,
      color1: "#445B72",
      color2: "#FFFFFF",
    },
    {
      name: "Moonstone",
      value: "#4098BF",
      path: Moonstone,
      color1: "#4098BF",
      color2: null,
    },
    {
      name: "Gradal Moonstone",
      value: "linear-gradient(to bottom, #4098BF, #FFFFFF)",
      path: GradalMoonstone,
      color1: "#4098BF",
      color2: "#FFFFFF",
    },
    {
      name: "Light Moonstone",
      value: "#77b1d4",
      path: LightMoonstone,
      color1: "#77b1d4",
      color2: null,
    },
  ],
  mirror: [
    {
      name: "Racing Scarlet",
      value: "#FF2400",
      path: RacingScarlet,
      color1: "#FF2400",
      color2: null,
    },
    {
      name: "Tropical Green",
      value: "#00755E",
      path: TropicalGreen,
      color1: "#00755E",
      color2: null,
    },
    {
      name: "Gold Dust",
      value: "#E2B227",
      path: GoldDust,
      color1: "#E2B227",
      color2: null,
    },
    {
      name: "Chrome",
      value: "#E5E4E2",
      path: Chrome,
      color1: "#E5E4E2",
      color2: null,
    },
    {
      name: "Cool Blue",
      value: "#4981CE",
      path: CoolBlue,
      color1: "#4981CE",
      color2: null,
    },
  ],
};
