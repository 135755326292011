import React, { useState } from "react";
import TitanLogo from "../../assets/images/titan-logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { VscChromeClose } from "react-icons/vsc";
import SBICardBanner from "../../assets/images/SBI_Card_Banner.png";
import Button from "@mui/material/Button";
import { BUTTON_LABELS } from "../../utils/constants";
import CompareIcon from "../../assets/icons/Compare-Icon.svg";
import MobileBookTexture from "../../components/MobileBookTexture";
import { TechContext } from "../../context/technologyContext";
interface LensCategoriesProps {
  isCompareTech: boolean;
  setIsCompareTech: (isCompareTech: boolean) => void;
  activeENV: string;
  isBookObject: boolean;
  setIsBookObject: (isBookObject: boolean) => void;
  isMobileObject: boolean;
  setIsMobileObject: (isBookObject: boolean) => void;
}

const LensCategories: React.FC<LensCategoriesProps> = ({
  isCompareTech,
  setIsCompareTech,
  activeENV,
  isBookObject,
  setIsBookObject,
  isMobileObject,
  setIsMobileObject,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const techContext = React.useContext(TechContext);
  const compareTechnologies = () => {
    if (!isCompareTech) {
      if (techContext?.technology) {
        const updatedTechnology = techContext.technology.map((techItem) => {
          return { ...techItem, isLensColor: true };
        });
        techContext.setTechnology(updatedTechnology);
      }
      setTimeout(function() { setIsCompareTech(true); }, 300);
      
    } else {
      setIsCompareTech(false);
      window.location.reload();
    }
  };

  return (
    <>
      <header className="header desktop-header">
        <nav className="navbar navbar-expand-lg">
          <div className="logo">
            <a href="/">
              <img src={TitanLogo} alt="TITAN EYE+" />
            </a>
          </div>
          <img className="navbar__sbi-card-banner" src={SBICardBanner} />
          <MobileBookTexture
              activeENV={activeENV}
              isBookObject={isBookObject}
              setIsBookObject={setIsBookObject}
              isMobileObject={isMobileObject}
              setIsMobileObject={setIsMobileObject}
            />
          <Button
            variant="contained"
            className="navbar__techcompare-btn"
            onClick={compareTechnologies}
          >
            <span className="navbar__techcompare-btn-content">
            <img src={CompareIcon} alt="CompareIcon" className="" />
            {isCompareTech
              ? BUTTON_LABELS.CANCEL_COMPARISON
              : BUTTON_LABELS.COMPARE_WITH_OTHER_TECH}
            </span>
            
          </Button>
        </nav>
      </header>
      <header className="header mobile-header">
        <nav className="navbar navbar-expand-lg">
          <div className="navbar__menu-wrapper">
            {isMenuOpen === false && (
              <RxHamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
            )}
            {isMenuOpen === true && (
              <VscChromeClose onClick={() => setIsMenuOpen(!isMenuOpen)} />
            )}
          </div>
          <div className="logo">
            <a href="/">
              <img src={TitanLogo} alt="TITAN EYE+" />
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default LensCategories;
